<style scoped  lang="less">
    .sort-title{ padding:0.75rem 0.5rem;font-size: 0.8rem;color: #3F4562;border-bottom: 0.06rem solid #E6E6E6 }
    .sort-not-list{ 
        display: flex; justify-content: flex-start; flex-wrap: wrap;padding: 0.5rem;}
    .sort-not-item{ width: 31%;text-align: center;margin-right: 0.5rem;margin-bottom: 0.5rem;}
    .sort-not-item:nth-child(3n){ margin-right: 0; }
    .sort{ padding:1rem 0.5rem;border: 0.06rem solid #E6E6E6;font-size: 0.7rem; }
    .sort-child-title{ padding:0.75rem 0.5rem; font-size: 0.8rem;color:#3F4562 }
    .sort-child-list{ display: flex; justify-content: flex-start; flex-wrap: wrap;padding: 0.5rem }
    .sort-child-item{ width: 31%;text-align: center;margin-right: 0.5rem;margin-bottom: 0.5rem;}
    .sort-child-item:nth-child(3n){ margin-right: 0; }
    .sort-box .actived{
        color: #ED6A0C;
        position: relative;
        overflow: hidden;
    }
    .sort-box .actived .selected-me{
        position: absolute;
        bottom: 0;right: 0;
        width: 1.4rem;height: 1.4rem;
    }
    .sort-box .actived .sort{
        border: 0.06rem solid #ED6A0C;
    }
    .sort-btn-box{
        padding: 0.5rem 3%;
        position: fixed;
        width: 94%;
        bottom: 0;
        box-shadow:0px 3px 6px rgba(0,0,0,0.16);
    }
    .sort-btn{
        background:linear-gradient(90deg,rgba(255,200,13,1) 0%,rgba(255,130,0,1) 100%);
        border-radius:2rem;height: 2.25rem;line-height: 2.25rem;text-align: center;
        color: #fff;
    }
</style>
<template>
    <div class="screening-drawer">
        <van-popup 
            v-model="show" 
            position="right"
            :style="{ height:'100%',width:'80%' }">
            <div class="sort-box">
                <div class="sort-title">镜架</div>
                <div class="sort-not-list">
                    <template v-for="pinItem in pinList">
                        <div class="sort-not-item" @click="handleClickSort(pinItem.id)" :class="actived===pinItem.id?'actived':''" :key="pinItem.id" v-if="!pinItem.children">
                            <div class="sort">{{pinItem.title}}</div>
                            <div class="selected-me" v-if="actived == pinItem.id">
                                <van-image
                                    width="1.4rem"
                                    height="1.4rem"
                                    :src="duigou"
                                />
                            </div>
                        </div>
                    </template>
                </div>
                <div class="sort-has-list">
                    <template  v-for="pinItem in pinList">
                        <div class="sort-has-item" :key="pinItem.id" v-if="pinItem.children">
                            <div class="sort-child-title">{{pinItem.title}}</div>
                            <div class="sort-child-list">
                                <div class="sort-child-item"  @click="handleClickSort(pinChildItem.id)" :class="actived===pinChildItem.id?'actived':''" v-for="pinChildItem in pinItem.children" :key="pinChildItem.id">
                                    <div class="sort">{{pinChildItem.title}}</div>
                                    <div class="selected-me" v-if="actived == pinChildItem.id">
                                        <van-image
                                            width="1.5rem"
                                            height="1.5rem"
                                            :src="duigou"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="sort-btn-box" @click="handleClickCloseScreen">
                    <div class="sort-btn">确定</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import duigou from "@/assets/image/duigou.png"
export default {
    name:"screening-drawer",
    props:{
        propShow:Boolean
    },
    watch:{
        //监听父组件传来的show
        propShow: {
            immediate: true,
            handler (v) {
                this.show = v;
            }
        },
        //修改父组件的show
        show(v){
            if(v === false){
                this.$emit("handleClickPropShow")
            }
        }
    },
    data(){
        return{
            duigou:duigou,
            show:true,
            actived:21,
            pinList:[
                { id:1,title:"HUMMER" },
                { id:2,title:"鸿观"},
                { id:3,title:"法国队"},
                { id:7,title:"推荐品牌",children:[
                    { id:21,title:'全真'},
                    { id:22,title:'蔡司'},
                ] },
                { id:8,title:"畅销品牌",children:[
                    { id:27,title:'鸿观'},
                    { id:28,title:'海一比真'},
                    { id:23,title:'洛基'},
                    { id:24,title:'罗敦司得'},
                    { id:25,title:'昂科美'},
                    { id:26,title:'黛玛诗'},
                ] },
            ]
        }
    },
    methods:{
        handleClickSort(id){
            this.actived  = id
        },
        //点击确定关闭抽屉
        handleClickCloseScreen(){
            this.show = false
        }
    }
}
</script>