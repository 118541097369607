<template>
    <div class="gray-bg home-good-list" v-htmltit data-title="商品列表">
        <go-back-btn></go-back-btn>
        <div class="shaixuanBox">
            <div class="home-good-top">
                <div class="home-good-top-left">
                    <van-icon name="arrow" /> 
                    <div class="search-val">{{titleStr}}</div>
                </div>
                <div class="home-good-top-right" @click="handleClickGoSearch">
                    <van-icon style="font-weight:bold;font-size:1rem" color="#FF976A" name="search" />
                </div>
            </div>
            <skeleton v-if="!loading"></skeleton>
            <div v-else class="home-good-sort">
                <div class="sort-item"
                @click="handleClickNew"
                :class="shunxu.type == 1?'actived':''" >
                    <div>上新</div>
                    <div></div>
                </div>
                <div class="sort-item price"
                @click="handleClickPrice(shunxu.price)"
                :class="shunxu.type == 2?'actived':''">
                    <div>价格</div>
                    <div :class="shunxu.price == 1?'rotating':''">
                        <span class="iconfont icon-shunxu" style="font-size:0.68rem"></span>
                    </div>
                </div>
                <div class="sort-item"
                @click="handleClickCount"
                :class="shunxu.type == 3?'actived':''">
                    <div>销量</div>
                    <div></div>
                </div>
                <!-- <div class="sort-item"
                @click="propShow = !propShow"> -->
                <div class="sort-item">
                    <div>筛选</div>
                    <div><span class="iconfont icon-filter"></span></div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="good-list">
            <go-cart-btn></go-cart-btn>
            <good-list :salelabelId="salelabelId"></good-list>
        </div>
        <screening-drawer  :propShow="propShow" @handleClickPropShow="handleClickPropShow"></screening-drawer>
    </div>
</template>
<script>
import './index.less'
import skeleton from "./component/skeleton"
import goodList from "@/view/Public/goodList"
import screeningDrawer from "@/view/Public/screeningDrawer"
import goCartBtn from "@/view/Public/goCartBtn"
import goBackBtn from "@/view/Public/goBackBtn"

export default {
    name:"good-lists",
    components:{skeleton,  goodList,screeningDrawer,goCartBtn,goBackBtn },
    data(){
        return{
            loading:true, //加载成功之前显示骨架页
            titleStr:"",
            shunxu:{
                type:1,  //1 上新  2 价格 3 销量
                price:1  //1 升序  2 降序
            },
            //右侧筛选抽屉
            propShow:false,
            salelabelId:"", //分类id
        }
    },
    mounted(){
        setTimeout(()=>{
            this.loading = true
        },1000)
       this.getSearchContent();
    },
    methods:{
        //去搜索页面
        handleClickGoSearch(){
            this.$toast("暂未开放")
            return;
            this.$router.push({
                name:"search"
            })
        },
        //获取顶部搜索条件
        getSearchContent(){
            let Oneclass = JSON.parse(sessionStorage.getItem('searchclassone'))
            let item = JSON.parse(sessionStorage.getItem('searchclasstwo'))
            let childItem = sessionStorage.getItem('searchclassthree') != 'undefined'?JSON.parse(sessionStorage.getItem('searchclassthree')):''
            if(Oneclass){
                this.titleStr = this.titleStr + Oneclass.name;
                this.salelabelId = Oneclass.id
            }
            if(item){
                this.titleStr = this.titleStr+ "/" + item.name;
                this.salelabelId = item.id
            }
            if(childItem){
                this.titleStr = this.titleStr+ "/" + childItem.name;
                this.salelabelId = childItem.id
            }
        },
        //点击上新
        handleClickNew(){
            this.$toast("暂未开放")
            return;
            this.shunxu.type = 1
        },
        //点击价格
        handleClickPrice(price){
            this.$toast("暂未开放")
            return;
            this.shunxu.type = 2
            if(price === 1){
                this.$toast('升序');
                this.shunxu.price = 2;
            }else if(price === 2){
                this.$toast('降序');
                this.shunxu.price = 1;
            }
        },
        //点击销量
        handleClickCount(){
            this.$toast("暂未开放")
            return;
            this.shunxu.type = 3
        },
        //切换抽屉的show 组件的propShow  start
        handleClickPropShow(v){
            this.propShow = v
        },
        //切换抽屉的show 组件的propShow  end
    }
}
</script>